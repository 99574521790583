import { loadRemoteEntry } from '@angular-architects/module-federation';
import { RemoteRouteDefinition } from '@app/shell/remote-route-definition';

const camelize = (s: string) => s.replace(/-./g, x => x.toUpperCase()[1]);

const setRoutes = (routes: RemoteRouteDefinition[]) => {
  window['remoteRoutes'] = routes.uniqBy((route: RemoteRouteDefinition) => route.name);
  return routes;
};

const fetchRemoteEntries = (routes: RemoteRouteDefinition[]) => {
  Promise.all(
    routes
      .uniqBy((route: RemoteRouteDefinition) => route.frontend)
      .map((route: RemoteRouteDefinition) => {
        return loadRemoteEntry({ type: 'script', remoteEntry: `/web/${route.frontend}/frontend/remoteEntry.js`, remoteName: camelize(route.frontend) })
          // eslint-disable-next-line no-console
          .catch((err: any) => console.log(`Error loading remote entry ${route.frontend}`, err));
      }));
};

const getMicroFrontends = fetch('/web/services/routes')
  .then(response => {
    if (!response.ok) {
      throw new Error(`route response returned ${response.status}: ${response.statusText}`);
    }
    return response.json();
  })
  .then(setRoutes)
  .then(fetchRemoteEntries)
  .catch(err => {
    // eslint-disable-next-line no-console
    console.error('Error while getting micro frontend routes', err);
    setRoutes([]);
  });

getMicroFrontends.then(() => import('./bootstrap'))
  // eslint-disable-next-line no-console
  .catch(err => console.error(err));
